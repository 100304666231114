import { render, staticRenderFns } from "./WhyLime24Tab.vue?vue&type=template&id=40078183&scoped=true&"
import script from "./WhyLime24Tab.vue?vue&type=script&lang=js&"
export * from "./WhyLime24Tab.vue?vue&type=script&lang=js&"
import style0 from "./WhyLime24Tab.vue?vue&type=style&index=0&id=40078183&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40078183",
  null
  
)

export default component.exports