<template>
  <div class="faqs">
    <div class="how-to-repay">
      <!-- How to repay my loan? -->
      <h4 class="heading-h4">How to repay my loan?</h4>

      <p class="b-body-1 mt-2">
        The amount according your Loan Agreement and your Debit order will be
        automatically debited from your bank account in the loan due date.
      </p>

      <!-- Can I repay the loan before the repayment date? -->
      <h4 class="heading-h4">
        Can I repay the loan before the repayment date?
      </h4>

      <p class="b-body-1 mt-2">
        That, of course, is your privilege. You should to press Log in button,
        sign in using your phone number and password used during registration,
        and made the early repayment settlement. After that, you should to pay
        the settlement in your bank. Pay attention: the settlement amount is
        actual on settle date.
      </p>

      <!-- How to repay the overdue loan -->
      <h4 class="heading-h4">How to repay the overdue loan</h4>

      <p class="b-body-1 mt-2">
        You should Log in, sign in using your phone number and password used
        during registration, and made the settlement. After that, you should to
        pay the settlement in your bank. Pay attention: the settlement amount is
        actual on settle date.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LimeLoansHowtorepaytab",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
