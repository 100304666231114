<template>
  <div class="faqs">
    <div class="why-lime24">
      <!-- Why should I choose Lime24? -->
      <h4 class="heading-h4">Why should I choose Lime24?</h4>

      <p class="b-body-1 mt-2">
        We are quick. You can get money transferred to your bank account as soon
        as your application approved. Applying for a loan is easy. To get a
        loan, you only need to provide basic information. We are affordable. You
        get discounts on interest if you repay your loan on time and we do not
        charge anything for early repayment.
      </p>

      <!-- How can I be sure I can trust Lime24? -->
      <h4 class="heading-h4">How can I be sure I can trust Lime24?</h4>

      <p class="b-body-1 mt-2">
        Lime24 service is owned by Lime Loans South Africa (Pty) Ltd, a private
        company registered in the Republic of South Africa with the Companies
        and Intellectual Property Commission (registration number
        2015/239349/07) and the National Credit Regulator (registration number
        CP8077). We operate in strict accordance with the regulations under the
        National Credit Act.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LimeLoansWhylime24tab",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
