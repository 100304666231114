<template>
  <div class="faqs">
    <v-card color="white" width="100%" flat tile>
      <v-container class="py-10">
        <v-row>
          <!-- FAQs -->
          <v-col cols="12">
            <h3 class="heading-h3">FAQs</h3>
          </v-col>

          <v-col cols="12">
            <!-- tabs -->
            <v-tabs
              class="b-body-1 text-decoration-none localfix"
              active-class="gray800--text"
              color="primary"
            >
              <!-- tabs-slider -->
              <v-tabs-slider class="mt-n2"></v-tabs-slider>

              <!-- Get the loan -->
              <v-tab class="b-body-1 pl-0 pr-0 text-capitalize">
                G<span class="text-lowercase">et the loan</span>
              </v-tab>

              <!-- Providing bank statement -->
              <v-tab class="b-body-1 px-3">
                P<span class="text-lowercase">roviding bank statement</span>
              </v-tab>

              <!-- How to repay -->
              <v-tab class="b-body-1 px-3">
                H<span class="text-lowercase">ow to repay</span>
              </v-tab>

              <!-- How DebiCheck works -->
              <v-tab class="b-body-1 px-3">
                <span class="text-capitalize"
                  >How DebiCheck <span class="text-lowercase"> works</span>
                </span>
              </v-tab>

              <!-- How to login -->
              <v-tab class="b-body-1 px-3">
                H<span class="text-lowercase">ow to login</span>
              </v-tab>

              <!-- Why Lime24 -->
              <v-tab class="b-body-1 pl-0 pr-0 text-decoration-none">
                <span class="text-capitalize">Why Lime24</span>
              </v-tab>

              <!-- Get the loan -->
              <v-tab-item class="mt-4">
                <v-card flat>
                  <v-row>
                    <v-col cols="12" md="10" lg="8">
                      <GetTheLoanTab />
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>

              <!-- Providing bank statement -->
              <v-tab-item class="mt-4">
                <v-card flat>
                  <v-row>
                    <v-col cols="12" md="10" lg="8">
                      <ProvidingBankStatementTab />
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>

              <!-- How to repay -->
              <v-tab-item class="mt-4">
                <v-card flat>
                  <v-row>
                    <v-col cols="12" md="10" lg="8">
                      <HowToRepayTab />
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>

              <!-- How DebiCheck works -->
              <v-tab-item class="mt-4">
                <v-card flat>
                  <v-row>
                    <v-col cols="12" md="10" lg="8">
                      <HowDebiCheckWorksTab />
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>

              <!-- How to login -->
              <v-tab-item class="mt-4">
                <v-card flat>
                  <v-row>
                    <v-col cols="12" md="10" lg="8">
                      <HowToLoginTab />
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>

              <!-- Why Lime24 -->
              <v-tab-item class="mt-4">
                <v-card flat>
                  <v-row>
                    <v-col cols="12" md="10" lg="8">
                      <WhyLime24Tab />
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import GetTheLoanTab from "@/components/public/pages/faqs/GetTheLoanTab";
import HowDebiCheckWorksTab from "@/components/public/pages/faqs/HowDebiCheckWorksTab";
import HowToLoginTab from "@/components/public/pages/faqs/HowToLoginTab";
import HowToRepayTab from "@/components/public/pages/faqs/HowToRepayTab";
import ProvidingBankStatementTab from "@/components/public/pages/faqs/ProvidingBankStatementTab";
import WhyLime24Tab from "@/components/public/pages/faqs/WhyLime24Tab";

export default {
  name: "LimeLoansFaqs",

  components: {
    GetTheLoanTab,
    HowDebiCheckWorksTab,
    HowToLoginTab,
    HowToRepayTab,
    ProvidingBankStatementTab,
    WhyLime24Tab,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
