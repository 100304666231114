<template>
  <div class="faqs">
    <div class="get-the-loan">
      <!-- Get the first loan -->
      <h4 class="heading-h4">Get the first loan</h4>

      <BlackIconList :listItems="listItems1" />

      <!-- Get the returning loan  -->
      <h4 class="heading-h4 mt-n5">Get the returning loan</h4>

      <BlackIconList :listItems="listItems2" />

      <!-- How fast can I get the money?   -->
      <h4 class="heading-h4 mt-n5">How fast can I get the money?</h4>

      <p class="b-body-1">
        You can get the money transferred to your bank account as soon as your
        application is approved. Pay attention: payments made before 2:00 pm
        between Monday to Friday will be credited into your bank account by 7pm
        on the same business day. Payments made after 2:00pm or on holidays will
        be credited to your bank account on the next business day by 7pm.
      </p>

      <!-- Why am I being asked to provide documents?   -->
      <h4 class="heading-h4">Why am I being asked to provide documents?</h4>

      <p class="b-body-1">
        To approve your application, we check information from multiply sources.
        Sometimes, however, this information is not sufficient and we ask you to
        send copies of certain documents.
      </p>

      <!-- Is it safe to tell you my bank account number? -->
      <h4 class="heading-h4">Is it safe to tell you my bank account number?</h4>

      <p class="b-body-1">
        It is absolutely safe to tell us your bank account number. Entering your
        bank account information is done using encrypted pages, protected by
        modern security tools. Our service is audited regularly by consultants
        from Trustwave Holdings, Inc. to make sure we comply with the
        requirements of the Visa and Mastercard international payment systems.
      </p>

      <!-- Why was my application declined?    -->
      <h4 class="heading-h4">Why was my application declined?</h4>

      <p class="b-body-1">
        The most common reason for declining your loan application is entering
        incorrect information in the questionnaire.
      </p>

      <!-- DebiCheck process flow diagram    -->
      <h4 class="heading-h4">DebiCheck process flow diagram</h4>

      <v-img
        alt="debicheck"
        class="debicheck hidden-xs-only"
        contain
        src="@/assets/img/public/pages/faqs/debicheck.png"
        max-width="770"
      />
    </div>
  </div>
</template>

<script>
import BlackIconList from "@/components/shared/lists/BlackIconList";

export default {
  name: "LimeLoansGettheloantab",

  components: { BlackIconList },

  data() {
    return {
      listItems1: [
        {
          text: `SA ID number`,
        },
        {
          text: `Your cellphone number`,
        },
        {
          text: `Your email`,
        },
        {
          text: `Bank account details`,
        },
        {
          text: `Employment details`,
        },
      ],
      listItems2: [
        {
          text: `Press “Log in” button, enter the mobile number that you used to
                registration`,
        },
        {
          text: `Verify your personal data and bank account`,
        },
        {
          text: `Confirm your income and costs. You need Bank statements up to recent 3
                months’ showing your income. To fill out costs info into a form on the
                site`,
        },
        {
          text: `Choose the loan amount you wish`,
        },
        {
          text: `Read and sign Pre-Agreement, Loan Agreement by clicking “I accept”
                button and money will be delivered to your bank account`,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
