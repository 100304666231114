<template>
  <div class="faqs">
    <div class="how-to-login">
      <!-- Can't sign in to account? -->
      <h4 class="heading-h4 mb-2">Can't sign in to account?</h4>

      <div class="ml-2">
        <v-list class="ml-n6 mt-4 transparent">
          <!--  -->
          <v-list-item class="mt-n8">
            <v-list-item-icon class="mt-3">
              <v-icon color="gray900">mdi-circle-medium</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="z-index ml-n7">
              <p class="b-body-1">
                If you can't remember your password you can
                <router-link
                  class="text-decoration-none b-body-1 primary--text font-weight-medium"
                  :to="{ name: 'PasswordRecoveryPage' }"
                >
                  recover password here
                </router-link>
              </p>
            </v-list-item-content>
          </v-list-item>

          <!--  -->
          <v-list-item class="mt-n8">
            <v-list-item-icon class="mt-3">
              <v-icon color="gray900">mdi-circle-medium</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="z-index ml-n7">
              <p class="b-body-1">
                If you don't have access to your account contact with Customer
                Service Department by email
                <a
                  href="mailto:support@lime24.co.za"
                  class="text-decoration-none font-weight-medium"
                  target="_blank"
                  >support@lime24.co.za</a
                >
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <!-- How can I change my phone number  -->
      <h4 class="heading-h4 mt-n4">How can I change my phone number</h4>

      <p class="b-body-1 mt-2">
        You can update your phone number by logging in and going to the "My
        details" section. Make sure you have your new phone number ready. Your
        main account should be tied with this phone number. If you don't have
        access to your account contact with Customer Service Department via
        email
        <a
          href="mailto:support@lime24.co.za"
          class="text-decoration-none font-weight-medium"
          target="_blank"
        >
          support@lime24.co.za
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LimeLoansHowtologintab",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
