<template>
  <div class="faqs">
    <div class="how-debicheck-works">
      <!-- What is DebiCheck? -->
      <h4 class="heading-h4">What is DebiCheck?</h4>

      <p class="b-body-1 mt-2">
        In support of the industry wide initiative by the Payments Association
        of South Africa (PASA), FNB has implemented DebiCheck to put you in
        control of your debit orders. Due to an increasing number of
        unauthorized debit orders being submitted into the payment system,
        DebiCheck will require you to electronically confirm the DebiCheck debit
        order details before a creditor or service provider can debit your
        account. You will be able to review the DebiCheck debit order details on
        your preferred channel, ensuring increased security and control of your
        debit orders. For more information, please visit
        <a
          href="https://www.DebiCheck.co.za"
          class="text-decoration-none font-weight-medium"
          target="_blank"
        >
          DebiCheck.co.za.
        </a>
      </p>

      <!-- What is DebiCheck for?  -->
      <h4 class="heading-h4">What is DebiCheck for?</h4>

      <p class="b-body-1 mt-2">
        The aim is to prevent fraud. DebiCheck helps avoid two cases: 1)
        Increase of debit orders being processed to bank accounts without
        permission (a mandate) from clients. 2) Clients who dispute debit orders
        that do have valid mandates, has also increased, which has become a huge
        concern for banks and companies. For more information, please visit
        <a
          href="https://www.DebiCheck.co.za"
          class="text-decoration-none font-weight-medium"
          target="_blank"
        >
          DebiCheck.co.za.
        </a>
      </p>

      <!-- How DebiCheck works? -->
      <h4 class="heading-h4">How DebiCheck works?</h4>

      <div class="ml-2">
        <BlackIconList :listItems="listItems1" />
      </div>

      <!-- Is DebiCheck safe to use?  -->
      <h4 class="heading-h4 mt-n4">Is DebiCheck safe to use?</h4>

      <p class="b-body-1 mt-2">
        The DebiCheck was introduced by PASA according to the prescribed
        industry standards, which makes the system secure. Your bank will
        explain how you can securely and electronically confirm your debit
        orders. For more information, please visit
        <a
          href="https://www.DebiCheck.co.za"
          class="text-decoration-none font-weight-medium"
          target="_blank"
        >
          DebiCheck.co.za.
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import BlackIconList from "@/components/shared/lists/BlackIconList";

export default {
  name: "LimeLoansHowdebicheckworkstab",

  components: { BlackIconList },

  data() {
    return {
      listItems1: [
        {
          text: `Choose loan amount and period.`,
        },
        {
          text: `Confirm your debit order. It means that you confirm the details of your
                debit order on an electronic device such as your cell phone, your bank
                app, your personal computer or perhaps an ATM. Your bank will let you
                know which options are available for you to use.`,
        },
        {
          text: `Lime24 will receive notification about confirming as soon as you
                familiarize and confirm order details. Payment confirmation is only once
                for each loan. For more information, please visit <a
                href="https://www.DebiCheck.co.za"
                class="text-decoration-none font-weight-medium"
                target="_blank">DebiCheck.co.za.
                </a>`,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
