<template>
  <div class="black-icon-list">
    <v-list class="ml-n6 mt-4 transparent">
      <v-list-item
        class="mt-n8"
        v-for="(listItem, index) in listItems"
        :key="index"
      >
        <v-list-item-icon class="mt-3">
          <v-icon color="gray900">mdi-circle-medium</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="z-index ml-n7">
          <p class="b-body-1" v-html="listItem.text"></p>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "LimeLoansBlackiconlist",

  props: {
    listItems: Array,
  },
};
</script>

<style lang="scss" scoped>
//
</style>
