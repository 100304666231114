<template>
  <div class="faqs-page">
    <FAQs />
  </div>
</template>

<script>
import FAQs from "@/components/public/pages/faqs/FAQs.vue";

export default {
  name: "LimeLoansFaqspage",

  components: { FAQs },

  metaInfo: {
    meta: [
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
