<template>
  <div class="faqs">
    <div class="providing-bank-statement">
      <!-- Using TruID -->
      <h4 class="heading-h4">Using TruID</h4>

      <p class="b-body-1 mt-2">
        TruID is a consent driven platform enabling consumers to securely
        connect with their Bank thereby facilitating the safe and convenient
        sharing of their information. In strict compliance with PoPIA, truID
        will
      </p>

      <p class="b-body-1 mb-0">
        The service is free and can be completed in under 1 minute.
      </p>

      <p class="b-body-1 ml-2">
        1. Approve & grant Consent <br />
        2. Verify your identity with your Bank <br />
        3. Authorise information to be shared
      </p>

      <p class="b-body-1">Is it Safe?</p>

      <div class="ml-2">
        <BlackIconList :listItems="listItems1" />
      </div>

      <p class="b-body-1">
        For more information please visit
        <a
          href="https://www.truID.co.za"
          class="text-decoration-none font-weight-medium"
          target="_blank"
        >
          www.truID.co.za
        </a>
      </p>

      <!-- Manual upload -->
      <h4 class="heading-h4">Manual upload</h4>

      <p class="b-body-1 mt-3 mb-3">
        You can upload documents manually by way you want from the list
      </p>

      <div class="ml-2">
        <BlackIconList :listItems="listItems2" />
      </div>
    </div>
  </div>
</template>

<script>
import BlackIconList from "@/components/shared/lists/BlackIconList";

export default {
  name: "LimeLoansProvidingbankstatementtab",

  components: { BlackIconList },

  data() {
    return {
      listItems1: [
        {
          text: `TruID employs a privacy & security principle by design`,
        },
        {
          text: `TruID guarantees the highest level of security when connecting you to
                your Bank`,
        },
        {
          text: `TruID acts as a proxy between the customer and the bank`,
        },
        {
          text: `Your private information is not visible, stored or shared with anyone`,
        },
      ],
      listItems2: [
        {
          text: `Using our web-form`,
        },
        {
          text: `Send them to our email <a href="mailto:support@lime24.co.za"
                class="text-decoration-none font-weight-medium" target="_blank"
              >support@lime24.co.za</a>`,
        },
        {
          text: `Send them to our WhatsApp <a href="https://wa.me/27717092666"
                class="text-decoration-none font-weight-medium" 
                target="_blank"> +27 071 7092 666 </a>`,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
